export default {
	data() {
		return {
			AddUsersForm: {
				account: '',
				birthday: '',
				gender: '',
				headImgUrl: '',
				idCard: '',
				mobile: '',
				rolesUuid: [],
				userCode: '',
				userType: 1,
				password: '',
				confirmPassword: '',
				AllRole: [],
				sourceType: 6,
			},
			//创建用户验证
			AddUsersFormrules: {
				account: [{
					required: true,
					message: '请输入用户名',
					trigger: 'blur'
				}, {
					min: 3,
					max: 15,
					message: '用户名长度在 3 到 15 个字符',
					trigger: 'blur'
				}],
				mobile: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				}, {
					validator: (rule, value, callback) => {
						if (!value) {
							return callback(new Error('手机号不能为空'));
						} else {
							const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
							console.log(reg.test(value));
							if (reg.test(value)) {
								callback();
							} else {
								return callback(new Error('请输入正确的手机号'));
							}
						}
					},
					trigger: 'blur'
				}],
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}],
				rolesUuid: [{
					required: true,
					message: '请选择角色',
					trigger: 'blur'
				}],
				confirmPassword: [{
					required: true,
					message: '请输入确认密码',
					trigger: 'blur'
				}, {
					validator: (rule, value, callback) => {
						if (value === '') {
							callback(new Error('请再次输入密码'))
						} else if (value !== this.AddUsersForm.password) {
							callback(new Error('两次输入密码不一致'))
						} else {
							callback()
						}
					},
					trigger: 'blur'
				}]
			}
		}
	},
	created() {
		this.getAllRole();
	},
	methods: {
		//select搜索角色列表
		getAllRole() {
			console.log(this.AddUsersForm)
			this.$axios
				.get("/api/web/role/findAll")
				.then(response => {
					this.AddUsersForm.AllRole = response.data.data
					var vm = this;
					if (this.AddUsersForm.AllRole != null) {
						this.AddUsersForm.AllRole.forEach(function(item1) {
							vm.$set(item1, 'value', null);
						})
					}
				})
				.catch(e => {
					console.log(e);
					/* this.$message({
						message: "请求失败",
						type: "error"
					}); */
				});
		},
		//创建用户
		submitForm(AddUsersForm) {
			console.log(this.AddUsersForm)
			this.$refs[AddUsersForm].validate((valid) => {
				if (valid) {
					this.AddUsersForm.roleUuid = this.AddUsersForm.AllRole.value;
					this.$axios({
						method: "post",
						url: "/api/web/user/create", // 接口地址
						data: this.AddUsersForm
					}).then(res => {
						if (res.data.code === 200) {
							this.$router.push({
								path: 'user-list'
							});
							this.$message({
								message: "创建成功",
								type: "succeed",
							});
						} else {
							this.$message({
								message: res.data.message,
								type: "error",
							});
						}
					}).catch(error => {
						console.log(error);
						//this.$message.error("请求失败");
					});
				}
			})

		},
		//重置
		resetForm(AddUsersForm) {
			this.$refs[AddUsersForm].resetFields();
		}
	}
}
